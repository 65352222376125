import React, { useEffect, useState } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import logo from '../images/utervTextLogo.png';
import facebookLogo from '../images/facebookLogo.png';
import instagramLogo from '../images/instagramLogo.png';
import youtubeLogo from '../images/youtubeLogo.png';
import tiktokLogo from '../images/tiktokLogo.png';
import linkedinLogo from '../images/linkedinLogo.png';
import hunLogo from '../images/hungary-flag.png';
import deLogo from '../images/germany-flag.png';
import enLogo from '../images/england-flag.png';


const Navbar = ({ lang, setLanguage }) => {
	const [open, setOpen] = useState(false);
	const [top, setTop] = useState(true);

	useEffect(() => {
		window.onscroll = () =>
			window.pageYOffset === 0 ? setTop(true) : setTop(false);
		return () => (window.onscroll = null);
	}, [top]);

	const handleLanguage = (language) => {
		setLanguage(language);
		setOpen(false);
	};

	return (
		<StyledNav
			style={
				top
					? { backgroundColor: 'rgb(255, 255, 255)' }
					: { backgroundColor: 'rgb(240, 240, 240)' }
			}
		>
			<div className='container'>
				<div className='navbar' id={!open ? '' : 'hidden'}>
					<div className='menuitems'>
						<AnchorLink to='/#hero'>
							<img className='logo' src={logo} alt='logo' />
						</AnchorLink>
						<button onClick={() => setOpen(false)}>
							<AnchorLink className='menuitem' to='/#about'>
								{lang === "hu" ? "Rólunk" : lang === "de" ? "Über uns" : "About us"}
							</AnchorLink>
						</button>
						<button onClick={() => setOpen(false)}>
							<AnchorLink className='menuitem' to='/#services'>
							{lang === "hu" ? "Szolgáltatásaink" : lang === "de" ? "Dienstleistungen" : "Services"}
							</AnchorLink>
						</button>
						<button onClick={() => setOpen(false)}>
							<AnchorLink className='menuitem' to='/#actions'>
							{lang === "hu" ? "Aktualitásaink" : lang === "de" ? "Aktualitäten" : "Seasonal"}
							</AnchorLink>
						</button>
						<button onClick={() => setOpen(false)}>
							<AnchorLink className='menuitem' to='/#catalog'>
							{lang === "hu" ? "Katalógus" : lang === "de" ? "Katalog" : "Catalogue"}
							</AnchorLink>
						</button>
						<button onClick={() => setOpen(false)}>
							<AnchorLink className='menuitem' to='/#documents'>
							{lang === "hu" ? "Dokumentumok" : lang === "de" ? "Unterlagen" : "Documents"}
							</AnchorLink>
						</button>
						<button onClick={() => setOpen(false)}>
							<AnchorLink className='menuitem' to='/#contact'>
							{lang === "hu" ? "Kapcsolat" : lang === "de" ? "Kontakt" : "Contact"}
							</AnchorLink>
						</button>
						<div className='social'>
							<a href='https://www.facebook.com/UTerv'>
								<img
									src={facebookLogo}
									alt='facebook-logo'
									className='social-logo'
								/>
							</a>
							<a href='https://www.instagram.com/u.terv.gravir/'>
								<img
									src={instagramLogo}
									alt='instagram-logo'
									className='social-logo'
								/>
							</a>
							<a href='https://m.youtube.com/channel/UCTfhp5A1ECj0an3qgYbTS9g'>
								<img
									src={youtubeLogo}
									alt='youtube-logo'
									className='social-logo'
								/>
							</a>
							<a href='https://www.tiktok.com/@uterv'>
								<img
									src={tiktokLogo}
									alt='tiktok-logo'
									className='social-logo'
								/>
							</a>
							<a href='https://www.linkedin.com/company/u-terv/'>
								<img
									src={linkedinLogo}
									alt='linkedin-logo'
									className='social-logo'
								/>
							</a>
						</div>
						<div className='mobile-languages'>
						<button
							className={lang === 'hu' ? 'active language-btn' : 'language-btn'}
							onClick={() => handleLanguage('hu')}
						>
							<img
									src={hunLogo}
									alt='hungary-flag'
									className={lang === 'hu' ? 'active-flag' : 'flag'}
								/>
							HU
						</button>
						<button
							className={lang === 'de' ? 'active language-btn' : 'language-btn'}
							onClick={() => handleLanguage('de')}
						>
							<img
									src={deLogo}
									alt='hungary-flag'
									className={lang === 'de' ? 'active-flag' : 'flag'}
								/>
							DE
						</button>
						<button
							className={lang === 'en' ? 'active language-btn' : 'language-btn'}
							onClick={() => handleLanguage('en')}
						>
							<img
									src={enLogo}
									alt='hungary-flag'
									className={lang === 'en' ? 'active-flag' : 'flag'}
								/>
							EN
						</button>
						</div>
					</div>
				</div>
				<AnchorLink to='/#hero' className='mobile-logo'>
					<img className='logo' src={logo} alt='logo' />
				</AnchorLink>
				<FontAwesomeIcon
					className='mobile-menu'
					icon={!open ? faBars : faTimes}
					onClick={() => setOpen(!open)}
				/>
			</div>
		</StyledNav>
	);
};

export default Navbar;

const StyledNav = styled.div`
	position: sticky;
	top: 0;
	z-index: 20;
	transition: 0.5s all ease-in-out;

	.container {
		width: 100%;
		height: 100px;
		margin: 0 auto;
		padding: 0 1rem;
		display: flex;
		align-items: center;
	}

	.logo {
		width: 100px;
		height: 45px;
		margin-bottom: 10px;
		transition: all 0.1s ease-in-out;
	}

	.logo:active {
		transform: scale(0.95);
	}

	.navbar {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.languages {
    border:0px;
    outline:0px;
    background-color:white;
    background:none;
    -webkit-appearance:none;
    appearance:none;
	color: rgb(55, 100, 150);
	font-size: 1rem;
	font-weight: 700;
	transition: all 0.1s ease-in-out;
	}

	.languages:hover {
		cursor: pointer;
	}

	.menuitems {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}

	.menuitem {
		color: #000000;
		font-size: 1.2rem;
		font-weight: 500;
		transition: all 0.1s ease-in-out;
	}

	.menuitem:hover {
		color: rgb(55, 100, 150);
		cursor: pointer;
	}

	.menuitem:focus {
		color: rgb(55, 100, 150);
	}

	button {
		background: none;
		border: none;
	}

	.flag{
		width: 20px;
		height: 10px;
		margin-right: 2px;
		transition: 0.2s all ease-in-out;
		filter: grayscale(100%);
	}

	.active-flag{
		width: 20px;
		height: 10px;
		margin-right: 2px;
		transition: 0.2s all ease-in-out;
		filter: grayscale(0%);
	}

	.social-logo {
		width: 30px;
		height: 30px;
		margin:0 0.25rem 0 0.25rem;
		cursor: pointer;
		transition: all 0.1s ease-in-out;
	}

	.social-logo:hover {
		transform: scale(1.05);
	}

	.social-logo:active {
		transform: scale(0.95);
	}

	.mobile-languages{
			button {
			color: rgb(156, 175, 195);
			transition: all 0.1s ease-in-out;
			font-weight:700;
			margin:0 0.25rem 0 0.25rem;
			:hover{
				cursor:pointer;
			}
			}
		
			.active {
		color: rgb(55, 100, 150);
		}
		}

	.mobile-menu {
		display: none;
	}

	.mobile-logo {
		display: none;
	}

	@media only screen and (max-width: 1024px) {
		.container {
			padding: 0 2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgb(240, 240, 240);
		}

		.mobile-logo {
			display: block;
		}

		.languages{
			display: none;
		}

		.social{
			margin-top: 0.3rem;
		}

		.mobile-languages{
			display:flex;
			width: 100%;
			align-items: center;
			justify-content: space-evenly;
			margin-top: 0.3rem;
			button {
				margin: 0.5rem !important;
				color: rgb(156, 175, 195);
				font-size: 1.2rem;
				font-weight: 700;
				transition: all 0.1s ease-in-out;
				:hover{
					cursor:pointer;
				}
				.flag{
					margin-bottom:2px;
				}
				.active-flag{
					margin-bottom:2px;
				}
			}
		
			.active {
				color: rgb(55, 100, 150);
			}
		}

		.mobile-menu {
			display: flex;
			min-width: 2rem;
			height: auto;
			transition: all 0.1s ease-in-out;
			cursor: pointer;
			color: #000000;
			.logo {
				margin-bottom: 15px;
			}
		}
		.mobile-menu:hover {
			transform: scale(1.1);
		}
		.navbar {
			display: none;
		}

		.menuitems {
			display: flex;
			flex-direction: column;
			button {
				margin:  0.2rem 0 0.2rem 0;
			}
		}

		.menuitems .logo {
			display: none;
		}

		.menuitem {
			font-weight: 400;
			margin: 0;
		}

		.social-logo {
			margin-right: 0.5rem;
		}

		button {
			margin-bottom: 0.3rem;
		}

		#hidden {
			position: absolute;
			left: 0;
			top: 100px;
			height: 310px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			transition: all 0.5s ease-in-out;
			background: rgb(240, 240, 240);
		}
		#hidden .menuitem {
			font-size: 1.5rem;
		}
	}
	@media only screen and (max-width: 480px) {
		.container {
			padding: 0 1rem;
		}
	}
`;
