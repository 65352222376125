import React from 'react';
import styled from 'styled-components';
import pdfLogo from '../images/pdf_logo.png';


const Documents = ({ documentsData, lang }) => {
	return (
		<StyledDocuments id='documents'>
            <div className="title-container">
            <h1 className='title'>{lang === "hu" ? "Dokumentumok" : lang === "de" ? "Unterlagen" : "Documents"}</h1>
            </div>
                <div className="container">
                {lang === "hu" ? documentsData.nodes[0].pdf.map((document) => 
                    <div className='document' key={document.title}>
                        <div className='logo-container'>
                            <a href={document.file.url} target='_blank' rel='noreferrer'>
                                <img
                                    src={pdfLogo}
                                    className='pdf_logo'
                                    alt={document.title}
                                />
                            </a>
                        </div>
                        <h4 className='document-title'>{document.title}</h4>
                    </div>
                    )
                : 
                documentsData.nodes[1].pdf.map((document) => 
                    <div className='document' key={lang === "de" ? document.title.split("|")[0] : document.title.split("|")[1]}>
                        <div className='logo-container'>
                            <a href={document.file.url} target='_blank' rel='noreferrer'>
                                <img
                                    src={pdfLogo}
                                    className='pdf_logo'
                                    alt={lang === "de" ? document.title.split("|")[0] : document.title.split("|")[1]}
                                />
                            </a>
                        </div>
                        <h4 className='document-title'>{lang === "de" ? document.title.split("|")[0] : document.title.split("|")[1]}</h4>
                    </div>)}
                    </div>
                    <div className='line'></div>
		</StyledDocuments>
	);
};

export default Documents;

const StyledDocuments = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: rgb(255, 255, 255);
    padding-bottom: 2rem;
	position: relative;

	.container {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        padding: 2rem;
	}

    .document {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .document-title {
            color: rgb(57, 62, 70);
			font-size: .9rem;
			font-weight: 700;
	}

    .pdf_logo{
        width: 50px;
        height: auto;
    }

    .title-container {
		background: rgb(240, 240, 240);
		width: 100%;
		text-align: center;
	}

    .title{
        color: rgb(57, 62, 70);
		font-size: 1.8rem;
		font-weight: 500;
		padding: 1rem;
    }

    .document-title{
        text-align:center;
    }

    .line {
		position: absolute;
		height: 2px;
		width: 100%;
		background-color: rgb(240, 240, 240);
		bottom: 0;
	}

    @media only screen and (max-width: 768px) {
		.container {
		    grid-template-columns: repeat(2, 1fr);
	    }

        .pdf_logo{
            width: 45px;
            height: auto;
        }
    }

    @media only screen and (max-width: 480px) {
		.title {
			font-size: 1.5rem;
		}

        .document-title {
			font-size: .8rem;
			font-weight: 600;
	}
    
    }
`;