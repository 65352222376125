import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';

const Gallery = ({ galleryData, lang }) => {
	const [images, setImages] = useState([]);

	useEffect(() => {
		setImages([]);
		galleryData.map((pic) => {
			const image = {
				original: pic.gatsbyImageData.images.fallback.src,
				thumbnail: pic.gatsbyImageData.images.fallback.src,
				thumbnailAlt: pic.title,
				originalAlt: pic.title,
				originalClass: 'images',
			};
			return setImages((images) => [...images, image]);
		});
	}, [galleryData]);

	return (
		<StyledGallery id='catalog'>
			<div className='title-container'>
				<h1 className='title'>{lang === "hu" ? "Katalógus" : lang === "de" ? "Katalog" : "Catalogue"}</h1>
			</div>
			<ImageGallery
				className='image-gallery'
				items={images}
				lazyLoad={true}
				showBullets={false}
			/>
			<div className='line'></div>
		</StyledGallery>
	);
};

export default Gallery;

const StyledGallery = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 2rem;
	position: relative;
	.title {
		color: rgb(57, 62, 70);
		font-size: 1.8rem;
		font-weight: 500;
	}

	.title-container {
		background: rgb(240, 240, 240);
		padding: 1rem;
		width: 100%;
		text-align: center;
	}

	.image-gallery {
		width: 90vw;
	}

	.image-gallery-image {
		height: 80vh;
	}

	.line {
		position: absolute;
		height: 2px;
		width: 100%;
		background-color: rgb(240, 240, 240);
		bottom: 0;
		z-index: -50;
	}

	@media only screen and (max-width: 768px) {
		.image-gallery-image {
			height: 400px;
			width: 400px;
		}
	}
	@media only screen and (max-width: 480px) {
		.title {
			font-size: 1.5rem;
		}

		.image-gallery-image {
			height: 300px;
			width: 300px;
		}
	}
	@media only screen and (max-width: 320px) {
		.image-gallery-image {
			height: 250px;
			width: 250px;
		}
	}
`;
