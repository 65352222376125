import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Hero = ({ heroData, lang }) => {
	
	return (
		<StyledHero className='hero' id='hero'>
			<div className='container'>
				<GatsbyImage
					image={heroData.edges[0].node.heroLogo.gatsbyImageData}
					alt='uterv-logo'
					className='logo'
				/>
				<h1 className='title'>{lang === "hu" ? heroData.edges[0].node.heroTitle : lang ==="de" ? heroData.edges[1].node.heroTitle.split("|")[0] : heroData.edges[1].node.heroTitle.split("|")[1]}</h1>
			</div>
		</StyledHero>
	);
};

export default Hero;

const StyledHero = styled.div`
	height: 100vh;
	flex-direction: column;

	.container {
		height: 80%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0rem 1rem;
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	.logo {
		width: 200px;
		height: 200px;
		margin-bottom: 3rem;
	}

	.title {
		font-size: 5rem;
		font-family: 'Times New Roman', Times, serif;
		text-align: center;
		font-weight: 300;
		color: rgb(0, 0, 0);
		text-shadow: 0px 2px 2px #d1d1d1;
		margin-bottom: 2rem;
	}

	@media only screen and (max-width: 1024px) {
		.title {
			font-size: 4rem;
		}
	}

	@media only screen and (max-width: 768px) {
		.title {
			font-size: 3rem;
		}
	}

	@media only screen and (max-width: 480px) {
		.title {
			font-size: 2.5rem;
		}
		.logo {
			width: 150px;
			height: 150px;
			margin-bottom: 1rem;
		}
	}
	@media only screen and (max-width: 320px) {
		.title {
			font-size: 2rem;
		}
		.logo {
			width: 100px;
			height: 100px;
			margin-bottom: 1rem;
		}
	}
`;
