import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Actions from '../components/Actions';
import Gallery from '../components/Gallery';
import Documents from '../components/Documents';
import Contact from '../components/Contact';
import Seo from '../components/Seo';

import '../styles/global.css';
import { graphql } from 'gatsby';

const Index = (rootQuery) => {
	const [lang, setLang] = useState('hu');
	const heroData = rootQuery.data.allContentfulHero;
	const aboutData = rootQuery.data.allContentfulAbout;
	const serviceData = rootQuery.data.allContentfulServices;
	const servicesData = rootQuery.data.allContentfulService;
	const actionsData = rootQuery.data.allContentfulActions;
	const galleryData = rootQuery.data.allContentfulGallery.edges[0].node.galleryPics;
	const documentsData = rootQuery.data.allContentfulDocuments;
	const contactData = rootQuery.data.allContentfulContact;
	const nameCardData = rootQuery.data.allContentfulNameCard;
	

	const setLanguage = (language) => {
		setLang(language);
	};

	return (
		<>
			<Seo title='U-Terv' />
			<Navbar lang={lang} setLanguage={setLanguage} />
			<Hero lang={lang} heroData={heroData} />
			<About lang={lang} aboutData={aboutData} />
			<Services lang={lang} serviceData={serviceData} servicesData={servicesData} />
			<Actions lang={lang} actionsData={actionsData} />
			<Gallery lang={lang} galleryData={galleryData} />
			<Documents lang={lang} documentsData={documentsData} />
			<Contact lang={lang} contactData={contactData} nameCardData={nameCardData} />
		</>
	);
};
export default Index;

export const rootQuery = graphql`
	query {
		allContentfulHero {
			edges {
			  node {
				heroTitle
				node_locale
				heroLogo {
				  gatsbyImageData
				}
			  }
			}
		  }
		allContentfulAbout {
			edges {
			  node {
				aboutTitle
				aboutWelcome
				altTitle
				altTitle2
				childContentfulAboutAboutDescriptionTextNode {
				  aboutDescription
				}
				engineerTitle
				engineerDescription {
					engineerDescription
				}
				node_locale
				aboutImage {
				  gatsbyImageData
				  description
				}
			  }
			}
		}
		allContentfulService (sort: { order: ASC, fields: createdAt }) {
			edges {
			  node {
				id
				node_locale
				serviceDescription
				serviceTitle
				serviceLogo {
				  title
				  file {
					url
				  }
				}
			  }
			}
		  }
		
		  allContentfulServices {
			edges {
			  node {
				node_locale
				servicesTitle
				servicesImage {
				  gatsbyImageData
				}
			  }
			}
		  }
		  allContentfulActions {
			edges {
			  node {
				node_locale
				altTitle
				actionImage {
				  gatsbyImageData
				  title
				}
				title
			  }
			}
		  }
		allContentfulGallery {
			edges {
				node {
					galleryPics {
						gatsbyImageData
						title
						description
					}
				}
			}
		}
		allContentfulDocuments {
			nodes {
			  node_locale
			  pdf {
				file {
				  url
				}
				title
			  }
			}
		  }
		allContentfulContact {
			nodes {
			  adress
			  email
			  info
			  phone
			  privacyPolicyEmail
			  node_locale
			}
		}
		allContentfulNameCard {
			nodes {
			  Name
			  Role
			  email
			  phone
			  url
			  photo {
				gatsbyImageData
			  }
			  node_locale
			}
	    }
	}
`;
