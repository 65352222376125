import React from 'react';
import textlogo from '../images/utervTextLogo.png';
import iconlogo from '../images/utervIconLogo.png';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';


const NameCard = ({ nameCard, lang}) => {
	return( <>
	{nameCard.lang === lang ?
		<StyledNameCard className='namecard-container'>
			<div className="logo-container">
				<div className="firstlogo">
					<img className='iconlogo' src={iconlogo} alt='iconlogo' />
				</div>
				<div className="secondlogo">
					<img className='textlogo' src={textlogo} alt='textlogo' />
					<p>{lang === "hu" ? "Mérnöki Iroda" : lang ==="de" ? "Ingenieurbüro" : "Engineering Office"}</p>
				</div>
			</div>
			<div className="data-container">
				<div className="data">
					<h2>{nameCard.name}</h2>
					<h3>{nameCard.role}</h3>
					<p>Tel.: {nameCard.phone}</p>
					<p>Email: {nameCard.email}</p>
					<p>Web: <a href={nameCard.url}>{nameCard.url}</a></p>
				</div>
				<GatsbyImage
					image={nameCard.img}
					alt='photo'
					className='photo'
				/>
			</div>
		</StyledNameCard>
		: null}</>
		);
};

export default NameCard;

const StyledNameCard = styled.div`

	width: 480px;
	height:260px;
	background-color: grey;
	background: rgb(255, 255, 255);
	filter: drop-shadow(0px 6px 3px #7e7e7e);
	z-index: 5;
	border-radius: 2px;
	border: #e3e3e3 solid 1px;
.logo-container{
	display: flex;
	align-items: center ;
	justify-content:space-between;
}

.firstlogo{
	align-self: flex-start;
	justify-self: flex-start;
	padding: 1rem;
	.iconlogo{
		width:65px;
		
	}
}

.secondlogo{
	margin-right: 4rem;
	p{
		margin-right: 2.3rem;
		transform: translate(0, -15px);
		font-family: 'Times New Roman', Times, serif;
	}
}

.data-container{
	display: flex;
	align-items: center ;
	justify-content:space-evenly;
}

.data{
	text-align: start;
}

.photo{
	max-width: 120px;
}

@media only screen and (max-width: 1024px) {
	margin-bottom: 2rem;	
}

@media only screen and (max-width: 480px) {
	width: 375px;
	height:270px;
	padding: 1rem;
	.photo{
	max-width: 120px;
	}
	p{
		font-size: 0.9rem;
	}
	h2{
		font-size: 0.9rem;
	}
	h3{
		font-size: 0.9rem;
	}

	.secondlogo{
		margin-right: 2rem;
	}
}
`;