import React, { useEffect } from 'react';
import styled from 'styled-components';
import Service from './Service';
import { GatsbyImage } from 'gatsby-plugin-image';

const Services = ({ serviceData, servicesData, lang }) => {

		let services = [];

			servicesData.edges.forEach(element => {
				if(element.node.node_locale === "hu"){
					services.push({
						serviceDescription : element.node.serviceDescription,
						serviceTitle : element.node.serviceTitle,
						logoUrl : element.node.serviceLogo.file.url,
						logoTitle : element.node.serviceLogo.title,
						lang : "hu"
					})
				} else if (element.node.node_locale === "de"){
					services.push({
						serviceDescription : element.node.serviceDescription.split("|")[0],
						serviceTitle : element.node.serviceTitle.split("|")[0],
						logoUrl : element.node.serviceLogo.file.url,
						logoTitle : element.node.serviceLogo.title,
						lang : "de"
					})
					services.push({
						serviceDescription : element.node.serviceDescription.split("|")[1],
						serviceTitle : element.node.serviceTitle.split("|")[1],
						logoUrl : element.node.serviceLogo.file.url,
						logoTitle : element.node.serviceLogo.title,
						lang: "en"
					})
				}
			});
		
	return (
		<StyledServices id='services'>
			<div className='title-container'>
				<h2 className='title'>{lang === "hu" ? serviceData.edges[0].node.servicesTitle : lang ==="de" ? serviceData.edges[1].node.servicesTitle.split("|")[0] : serviceData.edges[1].node.servicesTitle.split("|")[1]}</h2>
			</div>
			<div className='container'>
				<div className='services-img-container'>
					<GatsbyImage
						image={serviceData.edges[0].node.servicesImage.gatsbyImageData}
						alt='services image'
						className='services-img'
					/>
				</div>
				<div className='services-list'>
					{services.length > 0 ? services.map((service, i) => {
							return  <Service lang={lang} key={i} service={service}/>
					}) : null}
				</div>
			</div>
		</StyledServices>
	);
};

export default Services;

const StyledServices = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #ffff;

	.container {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-gap: 1rem;
		padding: 0 5rem;
	}
	.title {
		color: rgb(57, 62, 70);
		font-size: 1.8rem;
		font-weight: 500;
	}

	.title-container {
		background: rgb(240, 240, 240);
		padding: 1rem;
		width: 100%;
		text-align: center;
		margin-bottom: 5rem;
	}

	.services-img-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
	}

	.services-img {
		width: 70%;
	}

	.services-list {
		display: grid;
		grid-template-columns: repeat(2, 50%);
		grid-gap: 1rem;
	}
	.service {
		display: flex;
		align-items: center;
		height: 110px;
		background: rgb(250, 250, 250);
		box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
	}

	.services-icon-container {
		background: rgb(220, 220, 220);
		min-width: 107px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.service-icon {
		width: 40px;
		height: auto;
		filter: invert(30%) sepia(15%) saturate(454%) hue-rotate(181deg)
			brightness(93%) contrast(87%);
	}
	.service-description {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		padding: 0.5rem;
		h4 {
			color: rgb(57, 62, 70);
			font-size: 1.6rem;
			font-weight: 400;
		}

		p {
			color: rgb(0, 0, 0);
			font-size: 0.9rem;
			line-height: 15px ;
		}
	}
	@media only screen and (max-width: 1024px) {
		.container {
			grid-template-columns: 1fr;
			padding: 2rem;
		}
		.services-img-container {
			display: none;
		}
	}
	@media only screen and (max-width: 768px) {
		.title {
			font-size: 1.8rem;
		}
		.title-container {
			margin-bottom: 2rem;
		}

		.services-list {
			grid-template-columns: 1fr;
			grid-gap: 0.5rem;
		}
		.container {
			padding: 0 1rem;
			margin-bottom: 2rem;
		}
	}
`;
