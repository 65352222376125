import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const About = ({ aboutData, lang }) => {
	const [description, setDescription] = useState([]);
	useEffect(() => {
		let originalDescription = "";
		  if(lang === "hu"){
			originalDescription = aboutData.edges[0].node.childContentfulAboutAboutDescriptionTextNode.aboutDescription;
		} else if (lang === "de"){
			originalDescription = aboutData.edges[1].node.childContentfulAboutAboutDescriptionTextNode.aboutDescription.split("|")[0];
		} else {
			originalDescription = aboutData.edges[1].node.childContentfulAboutAboutDescriptionTextNode.aboutDescription.split("|")[1];
		}
		const newDescription = originalDescription.split('\n');
		setDescription(newDescription);
	
	}, [lang, aboutData.edges]);

	return (
		<StyledAbout id='about'>
			<div className='container'>
				<div className='aboutus'>
					<h2 className='title'>{lang === "hu" ? aboutData.edges[0].node.aboutTitle : lang ==="de" ? aboutData.edges[1].node.aboutTitle.split("|")[0] : aboutData.edges[1].node.aboutTitle.split("|")[1]}</h2>
					<h4 className='welcome'>{lang === "hu" ? aboutData.edges[0].node.aboutWelcome : lang ==="de" ? aboutData.edges[1].node.aboutWelcome.split("|")[0] : aboutData.edges[1].node.aboutWelcome.split("|")[1]}</h4>
					<h4 className='title'>{lang === "hu" ? aboutData.edges[0].node.altTitle : lang ==="de" ? aboutData.edges[1].node.altTitle.split("|")[0] : aboutData.edges[1].node.altTitle.split("|")[1]}</h4>
					<h4 className='altTitle'>{lang === "hu" ? aboutData.edges[0].node.altTitle2 : lang ==="de" ? aboutData.edges[1].node.altTitle2.split("|")[0] : aboutData.edges[1].node.altTitle2.split("|")[1]}</h4>
					{description.map((sentence, index) => {
						return <p className='description' key={index}>{sentence}</p>;
					})}
					<h2 className='title'>{lang === "hu" ? aboutData.edges[0].node.engineerTitle : lang ==="de" ? aboutData.edges[1].node.engineerTitle.split("|")[0] : aboutData.edges[1].node.engineerTitle.split("|")[1]}</h2>
					<p className='description'>{lang === "hu" ? aboutData.edges[0].node.engineerDescription.engineerDescription : lang ==="de" ? aboutData.edges[1].node.engineerDescription.engineerDescription.split("|")[0] : aboutData.edges[1].node.engineerDescription.engineerDescription.split("|")[1]}</p>			
				</div>
				<div className='aboutpic'>
					<GatsbyImage
						className='about-img'
						image={aboutData.edges[0].node.aboutImage.gatsbyImageData}
						alt={aboutData.edges[0].node.aboutImage.description}
					/>
				</div>
			</div>
		</StyledAbout>
	);
};

export default About;

const StyledAbout = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: rgb(240, 240, 240);
	margin-bottom: 5rem;
	.container {
		width: 100%;
		height: 700px;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
	}
	.title {
		color: rgb(57, 62, 70);
		font-size: 1.8rem;
		font-weight: 500;
		align-self: flex-start;
	}

	.altTitle {
		font-size: 1.2rem;
		font-weight: 500;
		margin-bottom: 0.5rem;
		color: rgb(0, 0, 0);
		margin-bottom: 0.5rem;
	}

	.welcome {
		font-size: 1.5rem;
		font-weight: 300;
		margin-bottom: 0.5rem;
		color: rgb(0, 0, 0);
	}

	.aboutus {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 2rem 3rem;
		max-width: 50%;
		position: relative;
	}

	.aboutpic {
		align-self: flex-end;
		height: 700px;
		max-width: 50%;
	}

	.about-img {
		width: 100%;
		height: 100%;
	}

	.description {
		color: rgb(57, 62, 70);
		font-size: 1.1rem;
		margin-bottom: 0.4rem;
		text-align: justify;
	}

	@media only screen and (max-width: 1200px) {
		.aboutus {
			padding: 1.5rem;
		}
		.title {
			font-size: 1.8rem;
		}
		.welcome {
			font-size: 1.5rem;
		}

		.description {
			font-size: 1rem;
		}
	}

	@media only screen and (max-width: 1024px) {
		.aboutus {
			padding: 1rem;
		}
		.title {
			font-size: 1.5rem;
		}
		.welcome {
			font-size: 1.2rem;
		}

		.description {
			font-size: .9rem;
		}
	}
	@media only screen and (max-width: 768px) {
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		.welcome {
			font-size: 2.5rem;
		}

		.aboutus {
			min-width: 100vw;
			padding: 3rem;
		}

		.aboutpic {
			display: none;
		}
	}
	@media only screen and (max-width: 480px) {
		.title {
			font-size: 1.8rem;
			margin-bottom: 0.5rem;
		}

		.welcome {
			font-size: 2rem;
		}
		.aboutus {
			padding: 2rem;
		}
	}
	@media only screen and (max-width: 320px) {
		.title {
			font-size: 1.5rem;
			margin-bottom: 0.3rem;
		}
		.description {
			font-size: 0.8rem;
		}
	}
`;
