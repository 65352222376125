import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Actions = ({ actionsData, lang }) => {

	return (
		<StyledSection className='actions' id='actions'>
			<div className='title-container'>
				<h1 className='title'>{lang === "hu" ? actionsData.edges[0].node.title : lang ==="de" ? actionsData.edges[1].node.title.split("|")[0] : actionsData.edges[1].node.title.split("|")[1]}</h1>
				<p className='altTitle'>{lang === "hu" ? actionsData.edges[0].node.altTitle : lang ==="de" ? actionsData.edges[1].node.altTitle.split("|")[0] : actionsData.edges[1].node.altTitle.split("|")[1]}</p>
			</div>
			<div className='container'>
				<GatsbyImage
					className='actions-img'
					image={actionsData.edges[0].node.actionImage.gatsbyImageData}
					alt={actionsData.edges[0].node.actionImage.title}
				/>
			</div>
		</StyledSection>
	);
};

export default Actions;

const StyledSection = styled.section`
	text-align: center;
	margin-top: 5rem;
	padding-bottom: 5rem;
	border-bottom: 2px solid rgb(240, 240, 240);;
	.title-container {
		background: rgb(240, 240, 240);
		padding: 1rem;
	}

	.container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.title {
		color: rgb(57, 62, 70);
		font-size: 1.8rem;
		font-weight: 500;
	}

	.altTitle {
		font-size: 1.5rem;
		font-weight: 300;
		color: rgb(0, 0, 0);
	}

	@media only screen and (max-width: 768px) {
		margin: 2rem 0rem;

		.altTitle {
			font-size: 1rem;
		}
	}
`;
