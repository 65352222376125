import React from 'react';
import NameCard from './NameCard';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMapMarkedAlt,
	faPhoneAlt,
	faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import GoogleMap from './GoogleMap';
import logo from '../images/utervIconLogo.png';
import logo2 from '../images/utervTextLogo.png';
import styled from 'styled-components';

const Contact = ({ contactData, lang, nameCardData }) => {

	let nameCards = [];
			nameCardData.nodes.forEach(element => {
			 	if(element.node_locale === "hu"){
					nameCards.push({
			 			name : element.Name,
			 			role : element.Role,
			 			email : element.email,
		         		phone : element.phone,
						url : element.url,
						img : element.photo.gatsbyImageData,
			 			lang : "hu"
			 		})
			 	} else if (element.node_locale === "de"){
					nameCards.push({
						name : element.Name,
						role : element.Role.split("|")[0],
						email : element.email,
						phone : element.phone,
					    url : element.url,
						img : element.photo.gatsbyImageData,
			 			lang : "de"
			 		})
			 		nameCards.push({
						name : element.Name,
						role : element.Role.split("|")[1],
						email : element.email,
						phone : element.phone,
					    url : element.url,
						img : element.photo.gatsbyImageData,
			 			lang: "en"
			 		})
			 	}
			 });

	return (
		<StyledContact id='contact'>
			<div className='container'>
				<div className='title-container'>
					<h1 className='title'>{lang === "hu" ? "Kapcsolat" : lang === "de" ? "Kontakt" : "Contact"}</h1>
				</div>
				<div className="contact-container namecards">
					{nameCards.length > 0 ? nameCards.map((nameCard, i) => {
							return  <NameCard lang={lang} key={i} nameCard={nameCard}/>
					}) : null}
				</div>
				<h4 className="info">{lang === "hu" ? contactData.nodes[0].info + " " + contactData.nodes[0].privacyPolicyEmail : lang ==="de" ? contactData.nodes[1].info.split("|")[0] + " " + contactData.nodes[0].privacyPolicyEmail : contactData.nodes[1].info.split("|")[1] + " " + contactData.nodes[0].privacyPolicyEmail}</h4>
				<div className='contact-container'>
					<div className='contact'>
						<FontAwesomeIcon icon={faMapMarkedAlt} className='logo' />
						<h4>{contactData.nodes[0].adress}</h4>
					</div>
					<div className='contact'>
						<FontAwesomeIcon icon={faPhoneAlt} className='logo' />
						<a href={`tel:${contactData.nodes[0].phone}`}>
							<h4>{contactData.nodes[0].phone}</h4>
						</a>
					</div>
					<div className='contact'>
						<FontAwesomeIcon icon={faEnvelope} className='logo' />
						<a href={`mailto:${contactData.nodes[0].email}`}>
							<h4>{contactData.nodes[0].email}</h4>
						</a>
					</div>
				</div>
			</div>
			<div className='googlemap'>
				<GoogleMap className='map' />
			</div>
			<div className='footer'>
				<div className='footer-container'>
					<div className='footer-item'>
						<img className='logo' src={logo} alt='logo' />
					</div>
					<div className='footer-item'>
						<div className='menuitems'>
							<h4>{lang === "hu" ? "Menü" : lang === "de" ? "Menu" : "Home"}</h4>
							<AnchorLink className='menuitem' to='/#about'>
								<p>{lang === "hu" ? "Rólunk" : lang === "de" ? "Über uns" : "About us"}</p>
							</AnchorLink>
							<AnchorLink className='menuitem' to='/#services'>
								<p>{lang === "hu" ? "Szolgáltatásaink" : lang === "de" ? "Dienstleistungen" : "Services"}</p>
							</AnchorLink>
							<AnchorLink className='menuitem' to='/#actions'>
								<p>{lang === "hu" ? "Aktualitásaink" : lang === "de" ? "Aktualitäten" : "Seasonal"}</p>
							</AnchorLink>
							<AnchorLink className='menuitem' to='/#catalog'>
								<p>{lang === "hu" ? "Katalógus" : lang === "de" ? "Katalog" : "Catalogue"}</p>
							</AnchorLink>
							<AnchorLink className='menuitem' to='/#documents'>
								<p>{lang === "hu" ? "Dokumentumok" : lang === "de" ? "Unterlagen" : "Documents"}</p>
							</AnchorLink>
							<AnchorLink className='menuitem' to='/#contact'>
								<p>{lang === "hu" ? "Kapcsolat" : lang === "de" ? "Kontakt" : "Contact"}</p>
							</AnchorLink>
						</div>
					</div>
					<div className='footer-item'>
						<div className='contact-details'>
							<h4>{lang === "hu" ? "Kapcsolat" : lang === "de" ? "Kontakt" : "Contact"}</h4>
							<p className='footer-contact-title'>U-Terv</p>
							<p>{contactData.nodes[0].adress}</p>
							<p>{contactData.nodes[0].phone}</p>
							<p>{contactData.nodes[0].email}</p>
						</div>
					</div>
					<div className='footer-item'>
						<img className='logo2' src={logo2} alt='logo2' />
					</div>
				</div>
			</div>
		</StyledContact>
	);
};

export default Contact;

const StyledContact = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.container {
		width: 100%;
		text-align: center;
		padding-bottom: 2rem;
		background: rgb(255, 255, 255);
		filter: drop-shadow(0px 6px 3px #7e7e7e);
		z-index: 5;
	}

	.title-container {
		background: rgb(240, 240, 240);

		width: 100%;
		text-align: center;
	}

	.title {
		color: rgb(57, 62, 70);
		font-size: 1.8rem;
		font-weight: 500;
		padding: 1rem;
		margin-bottom: 2rem;
	}

	.contact-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		padding: 1rem 5rem;
	}

	.info{
		padding: 1rem 0rem;
		font-weight: 500;
	}

	.contact {
		display: flex;
		align-items: center;

		.logo {
			color: rgb(57, 62, 70);
			width: 50px;
			height: 50px;
			margin-right: 1rem;
		}

		h4 {
			color: rgb(0, 0, 0);
			font-size: 1.5rem;
			font-weight: 500;
		}
		h4:hover {
			color: rgb(55, 100, 150);
		}
	}

	.googlemap {
		width: 100%;
	}

	.footer {
		display: flex;
		align-items: center;
		justify-content: center;

		.footer-container {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			padding: 1rem 2rem;
		}

		.footer-item {
			justify-self: center;
			padding: 0 1rem;
		}

		.logo {
			width: 120px;
			height: 120px;
			margin: 0.5rem;
		}

		.logo2 {
			padding-top: 1rem;
		}

		.contact-details {
			h4 {
				color: rgb(3, 0, 0);
			}

			p {
				color: rgb(57, 62, 70);
			}
		}
		.menuitems {
			h4 {
				color: rgb(0, 0, 0);
			}

			p {
				color: rgb(57, 62, 70);
			}

			p:hover {
				color: rgb(55, 100, 150);
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		.contact-container {
			padding: 0 2rem;
		}
		.contact {
			h4 {
				font-size: 1.2rem;
			}
		}
	}

	@media only screen and (max-width: 1024px) {
		.contact {
			.logo {
				width: 40px;
				height: 40px;
				margin-right: 0.5rem;
			}
			h4 {
				font-size: 1rem;
			}
		}

		.namecards{
			flex-direction: column;
		}
	}

	@media only screen and (max-width: 768px) {
		.contact-container {
			flex-direction: column;
		}

		.contact {
			margin-bottom: 2rem;
			.logo {
				width: 50px;
				height: 50px;
				margin-right: 1rem;
			}
			h4 {
				font-size: 1.5rem;
			}
		}

		.footer {
			.footer-container {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				padding: 1rem;
			}

			.footer-item {
				padding: 0;
				margin-right: 1rem;
			}

			.logo {
				width: 100px;
				height: 100px;
			}
			.contact-details {
				margin-left: 1rem;
				h4 {
					padding-top: 0.5rem;
					color: rgb(57, 62, 70);
					font-size: 1rem;
				}

				p {
					color: rgb(57, 62, 70);
					font-size: 1rem;
				}
			}
			.menuitems {
				display: none;
			}
		}
	}

	@media only screen and (max-width: 480px) {
		.title {
			font-size: 1.5rem;
		}

		.contact-container {
			padding: 0 1rem;
		}

		.contact {
			margin-bottom: 1.5rem;
			.logo {
				width: 40px;
				height: 40px;
				margin-right: 0.5rem;
			}
			h4 {
				font-size: 1.2rem;
			}
		}

		.footer {
			.footer-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			.footer-item {
				margin-right: 0;
			}

			.logo {
				width: 100px;
				height: 100px;
			}

			.logo2 {
				width: 100px;
				height: auto;
				padding-top: 0;
				padding-bottom: 1rem;
			}

			.contact-details {
				margin: 0;
				text-align: center;
			}
		}
	}
`;
