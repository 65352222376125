import React from 'react';

const Service = ({ service, lang}) => {
		
	return( <>
	{ service.lang === lang ?
		<div className='service'>
			<span className='services-icon-container'>
				<img
					className='service-icon'
					src={service.logoUrl}
					alt={service.logoTitle}
				/>
			</span>
			<div className='service-description'>
				<div className='service-title'>
					<h4>{service.serviceTitle}</h4>
				</div>
				<p>{service.serviceDescription}</p>
			</div>
		</div>
		: null}</>
		);
};

export default Service;
